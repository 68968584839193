import React from "react"

import Layout from '../components/layout'
import SEO from '../components/seo'

const Contact = () => {
  return (
    <Layout>
      <SEO />
      <main>
        <div className="l-contact l-wrap">
          <h2 className="m-headign">お問い合わせ</h2>
          <div className="l-contact__form form">
            <form name="contact" method="POST" data-netlify="true" action="/success/">
              <input type="hidden" name="form-name" value="contact" />
              <div className="form__item">
                <dl>
                  <dt>氏名</dt>
                  <dd><input type="text" name="name" /></dd>
                </dl>
              </div>

              <div className="form__item">
                <dl>
                  <dt>メールアドレス</dt>
                  <dd><input type="email" name="email" /></dd>
                </dl>
              </div>

              <div className="form__item">
                <dl>
                  <dt>お問い合わせ内容</dt>
                  <dd><textarea name="message" rows="6"></textarea></dd>
                </dl>
              </div>

              <div className="form__item">
                <button type="submit" className="form__btn">送信する</button>
              </div>
            </form>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Contact